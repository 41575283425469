/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui"
import HomeLayout from "../components/home-layout"
import { SEO } from "gatsby-theme-swhwsys-core"
import { navigate } from "gatsby"

const Level0Services = () => (
	<HomeLayout>
		<SEO title="Level 0 Services" />

    <Heading as="h1"
      sx={{
        // textAlign: "center",
        mb: 2,
      }}>
        Level 0 Services
      </Heading>

		<Heading as="h2"
      sx={{
        // textAlign: "center",
        mb: 2,
      }}>
      Initial Free Introductory Engagement
    </Heading>

		<ul>
			<li>Meet stakeholders and leaders driving business change</li>
			<li>Explore and understand fundamental business value streams</li>
      <ul>
        <li>Existing value streams</li>
        <li>IoT device role and data contributing business value</li>
        <li>New/Changed value stream propositions</li>
      </ul>
			<li>Deliverable</li>
      <ul>
        <li>First impression recommendations</li>
        <li>Consulting proposal for Level 1, 2, and 3 Services</li>
      </ul>
		</ul>

		<div sx={{ display: "grid", placeItems: "center" }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{
            backgroundImage: (theme) =>
              `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.accent} 0%, ${theme.colors.highlight} 90% )`,
            color: "inherit",
            fontWeight: "bold",
            boxShadow: "default",
            transition: "all 0.3s ease",
            ":hover, :focus, :active": {
              boxShadow: "lg",
              backgroundImage: (theme) =>
                `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.highlight} 0%, ${theme.colors.accent} 90% )`,
            },
          }}
        >
          Back
        </Button>
      </div>

	</HomeLayout>
);

export default Level0Services;
